import React from "react"

export default function Logo({ fill = "" }) {
  return (
    <div className="flex main-logo" style={{ fill }}>
      <svg
        className="md:h-32 md:w-64 flex flex-start h-6 w-20 items-center"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 214 42.62"
      >
       <text className="font-light text-5xl font-maxeville" x="0" y="35" fill="white">SolarCost</text>
      </svg>
    </div>
  )
}
